
import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { Diagram } from '@interfaces/DiagramTypes'
import { UserType } from '~/domain/interfaces/UserTypes'
import { getDiagramViewUrl } from '@helpers/CommunityHelper'
import { Template } from '~/domain/interfaces/TemplateTypes'
import CommonContextMenu from '~/components/common/CommonContextMenu.vue'
import { DIAGRAM_ACTIONS } from '~/domain/constants/DiagramConst'
import AppActions from '~/domain/constants/AppActions'
import { instanceOfDiagram } from '~/domain/helpers'
import CommonDiagramCardNuxt from '~/components/common/CommonDiagramCardNuxt.vue'

@Component({
  components: { CommonDiagramCardNuxt, CommonContextMenu },
  name: 'DiagramCardGrid',
  computed: {
    ...mapGetters({
      userIsAuthenticated: 'userIsAuthenticated',
      authenticatedUser: 'authenticatedUser',
    }),
  },
})
export default class DiagramCardGrid extends Vue {
  @Prop({ required: true }) diagrams!: Diagram[] | Template[]
  @Prop({ default: 'auto' }) maxPerRow!: string
  @Prop({ default: true }) displayOwner!: boolean
  @Prop({ default: false }) starred!: boolean
  @Prop({ default: 'diagram-card-grid' }) id!: string
  @Prop({ default: true }) loadLazy!: boolean
  @Prop({ default: false }) exploreLoading!: boolean

  private userIsAuthenticated!: boolean
  private authenticatedUser!: UserType

  public listView = false

  public diagramActions = DIAGRAM_ACTIONS
  public menuVisible = false
  public menuPositionX = 0
  public menuPositionY = 0
  public menuDiagram = null as Diagram | null
  public menuCloseOnClick = true
  public menuItems = this.getMenuItems()

  getMenuItems(diagram?: Diagram | Template) {
    const isLiked =
      this.userIsAuthenticated &&
      this.authenticatedUser &&
      diagram &&
      instanceOfDiagram(diagram)
        ? (diagram as Diagram)?.likedByAuthenticatedUser
        : false

    return [
      {
        text: this.$t('wordCapitalOpen'),
        action: DIAGRAM_ACTIONS.open,
        visible: true,
      },
      {
        text: this.$t('openInNewTab'),
        action: DIAGRAM_ACTIONS.openNewTab,
        visible: true,
      },
      { text: null, action: null, visible: true }, // separator
      {
        text: this.$t('copyLink'),
        action: DIAGRAM_ACTIONS.copyLink,
        visible: true,
      },
      {
        text: this.$t('makeACopy'),
        action: DIAGRAM_ACTIONS.makeACopy,
        visible: true,
      },
      {
        text: isLiked
          ? this.$t('wordCapitalUnlike')
          : this.$t('wordCapitalLike'),
        action: isLiked ? DIAGRAM_ACTIONS.unlike : DIAGRAM_ACTIONS.like,
        visible: true,
      },
    ]
  }

  get cardColClasses(): string {
    switch (this.maxPerRow) {
      case '1':
        return `col-12 pl-0 pr-0`
      case '3':
        return `col-xxl-3 col-xl-4 col-lg-6 col-sm-6 col-12`
      case '4':
        return `col-xxl-3 col-xl-3 col-lg-4 col-sm-6 col-12`
      default:
        return `col-xxl-3 col-xl-3 col-lg-4 col-sm-6 col-12`
    }
  }

  get appUrl(): string {
    return this.$config.apiBaseURL || ''
  }

  getDiagramViewUrl(diagram: Diagram | Template) {
    return getDiagramViewUrl(diagram)
  }

  async showMenu(evt: MouseEvent, diagram: Diagram) {
    this.menuDiagram = diagram
    this.menuPositionX = evt.clientX
    this.menuPositionY = evt.clientY + 10
    this.menuItems = this.getMenuItems(diagram)

    this.$store.commit('contextMenu/setIsUpdating', true)

    this.menuVisible = true
    this.$store.commit('contextMenu/setIsVisible', {
      id: this.id,
      visible: true,
      target: this.menuDiagram,
    })
  }

  menuVisibleChanged(v: boolean) {
    this.menuVisible = v
  }

  beforeDestroy() {
    this.menuVisible = false
    this.$store.commit('contextMenu/setIsVisible', {
      id: this.id,
      visible: false,
      target: this.menuDiagram,
    })
  }

  diagramAction(item: any): void {
    if (!item?.action || !item.diagram) return
    const diagramWebUrl = getDiagramViewUrl(this.menuDiagram as Diagram)
    switch (item.action) {
      case DIAGRAM_ACTIONS.like:
      case DIAGRAM_ACTIONS.unlike:
        this.likeDiagram(item)
        break
      case DIAGRAM_ACTIONS.open:
        window.open(diagramWebUrl, '_self')
        break
      case DIAGRAM_ACTIONS.openNewTab:
        window.open(diagramWebUrl, '_blank')
        break
      case DIAGRAM_ACTIONS.fork:
      case DIAGRAM_ACTIONS.makeACopy:
        window.open(item.diagram?.link, '_self')
        break
      case DIAGRAM_ACTIONS.copyLink:
        navigator.clipboard.writeText(
          (location && location.origin ? location.origin : '') + diagramWebUrl
        )
        break
      default:
        break
    }
  }

  async likeDiagram(item: any): Promise<void> {
    await this.$store.dispatch(AppActions.LIKE_DIAGRAM, {
      action: item.action,
      diagram: item.diagram,
      context: {
        ...this.$nuxt.context,
        $route: this.$route,
      },
    })
  }
}
