import { render, staticRenderFns } from "./DiagramCardGrid.vue?vue&type=template&id=5e9be0f6"
import script from "./DiagramCardGrid.vue?vue&type=script&lang=ts"
export * from "./DiagramCardGrid.vue?vue&type=script&lang=ts"
import style0 from "./DiagramCardGrid.vue?vue&type=style&index=0&id=5e9be0f6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonDiagramCardNuxt: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/common/CommonDiagramCardNuxt.vue').default,CommonContextMenu: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/common/CommonContextMenu.vue').default})
