
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { formatMatchingSubstring } from '@helpers/helpers'
import { getProfileUrlByUsername } from '@helpers/CommunityHelper'
import { Diagram, DiagramAction } from '@/domain/interfaces/DiagramTypes'
import { Template } from '~/domain/interfaces/TemplateTypes'

const instanceOfDiagram = (object: Diagram | Template) => {
  return 'fuid' in object
}

export default Vue.extend({
  name: 'CommonDiagramCardNuxt',
  computed: {
    ...mapGetters({
      userIsAuthenticated: 'userIsAuthenticated',
      authenticatedUser: 'authenticatedUser',
    }),
    appUrl(): string {
      return this.$config.apiBaseURL || ''
    },
    textForDiagramCard(): { [key: string]: any } {
      return {
        open: this.$t('wordCapitalOpen'),
        openNewTab: this.$t('openInNewTab'),
        copyLink: this.$t('copyLink'),
        share: this.$t('wordCapitalShare'),
        makeACopy: this.$t('makeACopy'),
        like: this.$t('wordCapitalLike'),
        unlike: this.$t('wordCapitalUnlike'),
        fork: this.$t('wordCapitalFork'),
      }
    },
  },
  props: {
    diagram: {
      type: Object as Vue.PropType<Diagram | Template>,
    },
    listView: {
      type: Boolean,
    },
    cancelThumbnail: {
      type: Number,
    },
    thumbnailRefresh: {
      type: Number,
    },
    starred: {
      type: Boolean,
    },
    loadLazy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    highlightMatches(v: string): string {
      return formatMatchingSubstring(v, null)
    },
    ownerName(name: string): string {
      return this.highlightMatches(name)
    },
    selectUser(e: { evt: Event; user: string }): void {
      if (e && e.evt && e.user) {
        e.evt.preventDefault()
        e.evt.stopPropagation()
        this.$router.push({
          path: getProfileUrlByUsername(e.user),
        })
      }
    },
    clickOpenMenu(evt: MouseEvent): void {
      evt.preventDefault()
      evt.stopPropagation()
    },
    isDiagram(obj: Diagram | Template) {
      return instanceOfDiagram(obj)
    },
  },

  watch: {
    '$store.state.diagrams.diagramAction': function (action: DiagramAction) {
      if (instanceOfDiagram(this.diagram)) {
        try {
          if (action.fuid === (this.diagram as Diagram).fuid) {
            this.$store.commit('diagrams/updateDiagramLikes', {
              diagram: this.diagram,
              action: action,
            })
          }
        } catch (e) {}
      }
    },
  },
})
