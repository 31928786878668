
import Vue from 'vue'

export default Vue.extend({
  name: 'CommonContextMenu',
  props: {
    containerId: {
      type: String,
      default: 'diagram-card-grid',
    },
    menuItems: {
      type: Array,
    },
    actions: {
      type: Object,
      default: {
        delete: 'delete',
      },
    },
    menuPositionX: {
      type: Number,
    },
    menuPositionY: {
      type: Number,
    },
    menuVisible: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {
    id() {
      return this.containerId
    },
    globalIsVisible() {
      return this.$store.state.contextMenu.isVisible
    },
    target() {
      return this.$store.state.contextMenu.target
    },
    isUpdating: {
      get() {
        return this.$store.state.contextMenu.isUpdating
      },
      set(v: boolean) {
        this.$store.commit('contextMenu/setIsUpdating', v)
      },
    },
    isVisible: {
      get() {
        return (
          this.$store.state.contextMenu.visibleMenu[this.containerId] || false
        )
      },
      set(v: boolean) {
        this.$store.commit('contextMenu/setIsVisible', {
          id: this.containerId,
          visible: v,
          target: this.object,
        })
      },
    },
  },
  methods: {
    menuItemClick(item: { [key: string]: string | null }): void {
      if (!item.action) {
        return
      }
      this.emitAction(item.action, this.object)
    },

    emitAction(action: string | null, diagram: any): void {
      this.$emit('menu-item-click', {
        action: action,
        diagram: diagram,
      })
    },

    modelChanged(v: boolean) {
      // this.$emit('visibility-changed', true)
    },
  },
  watch: {
    menuVisible(newVal, oldVal) {
      this.isUpdating = true
      this.isVisible = newVal
    },
  },
})
