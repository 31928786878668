var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('diagram-card',{class:[
    _vm.listView ? 'm-diagram-card-list-view' : 'm-diagram-card-grid-view',
  ],attrs:{"fromCommunity":true,"view":_vm.listView ? 'list' : 'grid',"diagram":{
    id: _vm.diagram.id,
    fuid: _vm.diagram.fuid,
    name: _vm.diagram.name,
    link: _vm.diagram.link,
    lastEdit: _vm.diagram.lastEdit,
    likeCount: _vm.diagram.likeCount,
    viewCount: _vm.diagram.viewCount,
    forkCount: _vm.diagram.forkCount,
    objectCount: _vm.diagram.objectCount,
    thumbnail: _vm.diagram.thumbnailUrl,
    kind: _vm.diagram.kind,
    shares: _vm.diagram.shared,
    isDiagram: _vm.diagram.isDiagram,
    isTemplate: _vm.diagram.isTemplate,
    isTest: _vm.diagram.isTest,
    isCurrentUserOwner: _vm.diagram.isCurrentUserOwner,
    isStarred: _vm.diagram.starred,
    likedByAuthenticatedUser: _vm.diagram.likedByAuthenticatedUser,
    owner: {
      name: _vm.diagram.owner.name,
      photo: _vm.diagram.owner.photo,
      username: _vm.diagram.owner.username,
      id: _vm.diagram.owner.id,
    },
  },"appUrl":_vm.appUrl,"cancelThumbnail":1,"thumbnailRefresh":1,"language":_vm.textForDiagramCard,"disabled":false,"loadLazy":_vm.loadLazy},on:{"click-user":(v) => _vm.selectUser(v),"open-menu":(v) => _vm.clickOpenMenu(v),"show-menu":(v, diagram) => _vm.$emit('show-menu', v, diagram),"open-diagram":(v) => _vm.$emit('open-diagram', v),"refresh-menu":function($event){return _vm.$emit('refresh-menu')}}})
}
var staticRenderFns = []

export { render, staticRenderFns }