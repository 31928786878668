var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'd-flex': _vm.listView,
    'flex-column': _vm.listView,
    'm-diagram-cards-container': true,
    'm-diagram-cards-grid': !_vm.listView,
  }},[_vm._l((_vm.diagrams),function(diagram,index){return _c('div',{key:`${diagram.id || diagram.ID}-${index}`,class:[
      _vm.listView ? 'm-diagram-card-list-view' : 'm-diagram-card-grid-view',
    ]},[_c('nuxt-link',{staticClass:"u-url",attrs:{"to":_vm.getDiagramViewUrl(diagram)}},[(!_vm.exploreLoading)?_c('common-diagram-card-nuxt',{class:[
          _vm.listView ? 'm-diagram-card-list-view' : 'm-diagram-card-grid-view',
        ],attrs:{"view":_vm.listView ? 'list' : 'grid',"diagram":diagram,"appUrl":_vm.appUrl,"cancelThumbnail":1,"thumbnailRefresh":1,"loadLazy":_vm.loadLazy},on:{"show-menu":function($event){return _vm.showMenu($event, diagram)}}}):_c('v-skeleton-loader',{staticClass:"ma-auto d-flex flex-column skeleton-loader-diagram",attrs:{"type":"image, heading, heading, heading"}})],1)],1)}),_vm._v(" "),_c('common-context-menu',{attrs:{"containerId":_vm.id,"menuVisible":_vm.menuVisible,"menuPositionX":_vm.menuPositionX,"menuPositionY":_vm.menuPositionY,"menuItems":_vm.menuItems,"closeOnClick":_vm.menuCloseOnClick,"actions":_vm.diagramActions,"object":_vm.menuDiagram},on:{"menu-item-click":(v) => _vm.diagramAction(v),"visibility-changed":_vm.menuVisibleChanged}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }